<template>
  <div class="explain">
             <van-swipe :autoplay="3000">
                <van-swipe-item v-for="(item, index) in images" :key="index">
                    <img :src="item" />
                </van-swipe-item>
            </van-swipe>
          </div>
</template>

<script>
export default {
   data() {
        return {  
            activeNames: ['0'], 
            navtitle:"精美组图" ,
              images: [],
        }
    },
    created() {
        let data = {
            }
        this.$api.imgs(data,res=>{ 
                let arr = res.data.data.map((item)=>{
                    item=item.picUrl
                    return item
                })
                this.images=arr
        })
    }
}
</script>

<style scoped>
 .exhibition{
        width: 100%;
        height: 100%;
    }
    .explain{
        width: 100%;
        padding: 0 0.08rem;
        margin-bottom: 0.1rem;
        box-sizing: border-box;
    }
    .explain p{
        width: 100%;
        padding: 0 0.08rem;
        box-sizing: border-box;
        margin: 0.15rem 0;
    }
    .explain img{
        width: 100%;height: 2.1rem;
        display: block;
    }
    .explain .bac{
        color: #197cc3;
        font-size: 0.2rem;
        text-align: center;
    }
    .explain p:nth-of-type(3){
        font-size: 0.14rem;
        line-height: 0.20rem;
    }
    .tit{
        font-size: 0.14rem;
        line-height: 0.20rem;
    }
    .name{
        text-align: center;
        font-size: 0.16rem;
    }
</style>