
<template>
  <div>
        <div class="contents">
            <div class="main" v-for="(v,i) in arr" :key="i">
                  <p>{{$t(v.title)}}</p>
                <span>{{$t(v.text)}}</span>
                <img :src="v.url" alt="">
                  <img :src="v.url2" alt="" v-if="v.url2">
                  <img :src="v.url3" alt="" v-if="v.url3" class="imgbot">
                  <img :src="v.url4" alt="" v-if="v.url4" class="imgbot">
            </div>
         </div>
         <div class="tabb">

                   <table border="1" cellPadding="0" cellSpacing="0" bordercolor="#EBEBEB" style="text-align: center;">
                    <tbody>
                        <tr>
                            <!-- 会议室 -->
                            <th rowspan="2" style="background: #0F6FB4; color: #fff;"> {{$t('language.conferenceroom')}}</th>
                            <!-- 面积（m²） -->
                            <th rowspan="2" style="background: #197BC2; color:#fff;"> {{$t('language.msquared')}}</th>
                            <!-- 长*宽*高（m） -->
                            <th rowspan="2" style="background: #2285CD; color:#fff;"> {{$t('language.Lengthwidthheight')}}</th>
                            <!-- 可容纳人数 -->
                            <th colspan="4" style="background: #3092D9; color:#fff;"> {{$t('language.seatingcapacity')}}</th>
                            
                        </tr>
                        <tr class="thspan">
                            <!-- 课桌式 -->
                            <th  style="background: #3092D9; color:#fff;"> {{$t('language.classstyle')}}</th>
                            <!-- 剧院式 -->
                            <th  style="background: #3092D9; color:#fff;"> {{$t('language.theaterstyle')}}</th>
                            <!-- u字型 -->
                            <th  style="background: #3092D9; color:#fff;"> {{$t('language.converseU')}}</th>
                            <!-- 宴会 -->
                            <th class="thspan_right"  style="background: #3092D9; color:#fff;">{{$t('language.feast')}}</th>
                        </tr>
                        <tr class="trs" v-for="(item,index) in tablelist" :key="index">
                            <td>{{item.meetName}}</td>
                            <td>{{item.area}}</td>
                            <td>{{item.lengthWidthHeight}}</td>
                            <td>{{item.desk}}</td>
                            <td>{{item.theatre}}</td>
                            <td>{{item.uType}}</td>
                            <td>{{item.banquet}}</td>
                        </tr>
                    </tbody>
                </table>
                </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            activeNames: ['0'], 
            navtitle:"会议中心" ,
             arr:[
              {
                    "title":"language.conferencecenter",
                    // "title":"会议中心",
                    "text":"language.floorsandanarea",
                    // "text":"会议区共5层、面积3万平方米，根据需求设有不同规模的会议室共计30个，总计可容纳3600余人，可承接各类会议、宴会、论坛讲座、企业年会等大型室内活动。",
                        url:require('../../assets/image/组 57.png'),
                        url2:require('../../assets/image/会议中心1层前厅1.png')
                },
                {
                    "title":"language.PeopleLectureHall",
                    // "title":"千人报告厅",
                    "text":"language.framestage",
                    // "text":"12m挑高，镜框式舞台。 ",
                          url:require('../../assets/image/2层阶梯报告厅.png'),
                    
                },
                {
                    "title":"language.Onethousandpeople",
                    // "title":"千人宴会厅",
                    "text":"language.separabledesign",
                    // "text":"近2000平方米，可拆分设计，满足不同规模宴会需求。",
                        url:require('../../assets/image/1层宴会厅.png')
                   
                },
                 {
                    
                    "title":"language.Othermeetingrooms",
                    // "title":"其他会议室",
                        url:require('../../assets/image/3层中型会议室.png'),
                        url2:require('../../assets/image/洽谈室.png'),
                        url3:require('../../assets/image/组 58.png'),
                        url4:require('../../assets/image/2层贵宾厅.png'),
                   
                }
            ],
            tablelist:[]
        }
    },
    created() {
         this.conferencecenter()
    },
     methods:{
         // 会议中心table数据
        conferencecenter(){
            let data = {

            }
            this.$api.conferencecenter(data,res=>{
                if(res.data.code == 200){
                    this.tablelist = res.data.data
                }
            })
        },}
}
</script>

<style scoped>
      .tabb{
        text-align: center;
        margin-bottom: 0.2rem;
    }
    .phone img{
        width: 0.16rem;
        height: 0.16rem;
        vertical-align: middle;
        margin-right: 0.1rem;
    }
    .main{
        margin: 0.1rem;
    }
    .main p{
        font-size:0.18rem;
        font-weight: bold;
        margin-bottom: 0.1rem;
    }
    .main span{
       font-size: 0.12rem
    }
    .main img{
        width: 100%;
        margin-bottom: 0.1rem; 
    }
</style>