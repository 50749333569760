<template>
   <div class="explain">
              <div>
                <p class="bac">
                    {{ $t("language.Cityshoreseaofcloud") }}
                    <!-- 城岸云浪 -->
                </p>
                <!-- 烟台八角湾国际会展中心 -->
                <p class="tit name"> {{ $t("language.ExhibitionCenter") }}</p>
                <!-- 烟台八角湾国际会展中心位于烟台开发区八角街道，占地209.27亩，建筑面积20万平方米，总投资约32亿元，由美国著名设计公司AECOM根据第五代会展综合体定位设计“已成岸云浪·海上银贝”为设计理念，将烟台的碧海与蓝天相接，海浪与白云相交融，再融入轻盈的银贝，最终演化出建筑结果与造型相互统一、结构清晰的、及展览区、会议区和配套商业区为一体的商业会展体。 -->
                <p>
                   {{ $t("language.expl") }} 
                </p>
                <img src="../../assets/image/zghzzx.png" alt="">
              </div>
              <div>
                <p class="bac">
                    {{ $t("language.Theexhibitionarea") }} 
                    <!-- 展览区域 -->
                </p>
                <!-- 烟台八角湾国际会展中心位于烟台开发区八角街道，占地209.27亩，建筑面积20万平方米，总投资约32亿元 -->
                <p class="tit">{{ $t("language.YantaiBajiao") }}</p>
                <!-- 会展中心展览区分为室内展区和室外展区，市内共设有8个展厅，室内展览面积6.6万平方米，总展位3300个；室外展览面积约3.3万平方米，可为各类会展活动提供展览空间。 -->
                
                <p>{{ $t("language.Theexhibition") }}</p>
                    
                <!-- <img src="../../assets/image/zwjs.png" alt=""> -->
                <div class="conference">
                    <ul class="uul">
                         <li><span class="spann">8<sup>个</sup></span><p class="size">展厅</p></li>
                         <li><span class="spann">56000<sup>平方米</sup></span><p class="size">室内展览面积</p></li>
                         <li><span class="spann">2800<sup>个</sup></span><p class="size">展位</p></li>
                         <li><span class="spann">33000<sup>平方米</sup></span><p class="size">室外展览面积</p></li>
                    </ul>
                </div>
                <img src="../../assets/image/zghzzx.png" alt="">
              </div>
              <div>
                <p class="bac">
                    {{ $t("language.Assemblyarea") }} 
                    <!-- 议会区域 -->
                </p>
                <!-- 会展中心会议区共5层、面积3万平方米，根据需求设有不同规模的会议室共计30个，总计可容纳3600余人，可承接各类会议、宴会、论坛讲座、企业年会等大型室内活动。 -->
                <p class="tit">{{ $t("language.Theconference") }}</p>
                <!-- 此外，会展中心整体设计采用环保先进的建筑技术及智能系统，配备有智能停车技术的室外停车位1800余个；2500平方米的配套厨房可为参展商、会议等人员提供多种形式的美食享受。 -->
                <p>
                    {{ $t("language.Inaddition") }}
                </p>
                <!-- <img src="../../assets/image/zwrn.png" alt=""> -->
                <div class="conference">
                    <ul class="uul">
                         <li><span class="spann">5<sup>层</sup></span><p class="size">会议中心</p></li>
                         <li><span class="spann">30000<sup>平方米</sup></span><p class="size">总面积</p></li>
                         <li><span class="spann">30<sup>个</sup></span><p class="size">会议室</p></li>
                         <li><span class="spann">3600<sup>余人</sup></span><p class="size">可容纳</p></li>
                    </ul>
                </div>
                <img style="margin-bottom: 0.08rem;" src="../../assets/image/zghzzx.png" alt="">
              </div>
          </div>
</template>

<script>
export default {

}
</script>

<style scoped>
 .explain{
        width: 100%;
        padding: 0 0.08rem;
        box-sizing: border-box;
    }
    .explain p{
        width: 100%;
        padding: 0 0.08rem;
        box-sizing: border-box;
        margin: 0.15rem 0;
    }
    .explain img{
        width: 100%;height: auto;
        display: block;
    }
    .explain .bac{
        color: #197cc3;
        font-size: 0.2rem;
        text-align: center;
    }
    .explain p:nth-of-type(3){
        font-size: 0.14rem;
        line-height: 0.20rem;
    }
    .tit{
        font-size: 0.14rem;
        line-height: 0.20rem;
    }
    .name{
        text-align: center;
        font-size: 0.16rem;
    }
    .conference {
  width: 100%;
  height: 0.5rem;
  background-color: #197CC3;
}
.uul{
  width: 100%;
  display: inline-block;
  height: 0.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.uul li{
  display: inline-block;
  height: 0.4rem;
}
.spann{
  font-size: 0.25rem;
  color: white;
}
sup{
  font-size: 0.01rem;
  font-weight: 300;
}
.size{
  padding-left: 0.05rem;
  font-size: 0.01rem;
  color: white;
  margin-top: -0.05rem !important;
  padding: 0 !important;
}
</style>