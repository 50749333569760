<template>
  <div class="exhibition">
      <!-- 展馆概况 -->
      <div class="nav">
          <H5Carousel/>
      </div>
      <div class="content">
          <div class="navs">
              <van-collapse v-model="activeNames">
                <van-collapse-item :title="navtitle">
                    <div class="titleitem" v-for="(item,index) in nav" @click="gopage(index)" :key="index" :style="{background:item.background,color:item.color}">
                        {{show == true?item.title:item.titles}}
                    </div>
                </van-collapse-item>
              </van-collapse>
          </div>
         <div class="content" v-if="navtitle == '展馆概况'|| navtitle == 'Overview of the exhibition hall'">
            <Exhibition :tablelists="tablelist"/>
            </div>
            <div class="content" v-else-if="navtitle == '展览场地'|| navtitle == 'Exhibition ground'">
            <Site />
            </div>
            <div class="content" v-else-if="navtitle == '会议中心'|| navtitle == 'Conference Center'">
            <Conference/>
            </div>
            <div class="content" v-else-if="navtitle == '精美组图'|| navtitle == 'Exquisite group pictures'">
            <Survey />
            </div>
        </div>
      <div class="bot">
          <H5Bottom/>
      </div>
  </div>
</template>

<script>
import H5Carousel from '../../components/h5-carousel.vue'
import H5Bottom from '../../components/h5bottom.vue'
import Exhibition from '../../components/h5gallery/exhibition.vue'
import Conference from '../../components/h5gallery/conference.vue'
import Site from '../../components/h5gallery/site.vue'
import Survey from '../../components/h5gallery/survey.vue'
export default {
    components:{
        H5Carousel,H5Bottom,Exhibition,Conference,Site,Survey
    },
    data() {
        return {
             nav:[
                {title:"展馆概况",background: "#197cc3",color: "#fff",titles:"Overview of the exhibition hall" },
                {title:"展览场地",background: "#fff",color: "#000",titles:"Exhibition ground"},
                {title:"会议中心",background: "#fff",color: "#000",titles:"Conference Center"},
                {title:"精美组图",background: "#fff",color: "#000",titles:"Exquisite group pictures"},
                {title:"VR导览",background: "#fff",color: "#000",titles:"vr"},
            ],     
            activeNames: ['0'],
            navtitle:"",
            tablelist:[],
             show:""
        }
    },
    created() {
        if(this.$route.query.path == undefined || this.$route.query.path == '/'){
             this.navtitle = '展馆概况'
         }else{
             this.navtitle = this.$route.query.path
              let nav = this.nav
                let navlist =  nav.map((item)=>{
                    if(item.title == this.$route.query.path){
                        item.background = '#197cc3'
                        item.color = '#fff' 
                        if(localStorage.getItem('locale') == 'en-US'){
                            this.navtitle = item.titles
                        }else{
                            this.navtitle = item.title
                        }
                    }else{
                        item.background = '#fff'
                        item.color = '#000' 
                    }
                    return item
                })
                if(localStorage.getItem('locale') == 'en-US'){
                    this.show = false
                }else{
                    this.show = true
                }
             this.nav = navlist
         }

    },
    methods:{
        // // 酒店信息
        // hotelinformation(){
        //     let data = {

        //     }
        //     this.$api.hotelinformation(data,res=>{
        //         if(res.data.code == 200){
        //             this.tablelist = res.data.data
        //         }
        //     })
        // },
        gopage(val){
            let nav = this.nav
           let navlist =  nav.map((item,index)=>{
                // console.log(item,index);
                if(index == val){
                    item.background = '#197cc3'
                    item.color = '#fff' 
                }else{
                    item.background = '#fff'
                    item.color = '#000' 
                }
                return item
            })
            this.nav = navlist
           if(val == 0){
                if(localStorage.getItem('locale') == 'en-US'){
                    this.navtitle = 'Overview of the exhibition hall'
                }else{
                    this.navtitle = '展馆概况'
                }
               this.activeNames = ["0"]
               this.$router.replace({path:'/exhibition',query:{path:`展馆概况`}})
           }else if(val == 1){
             if(localStorage.getItem('locale') == 'en-US'){
                    this.navtitle = 'Exhibition ground'
                }else{
                    this.navtitle = '展览场地'
                }
               this.activeNames = ["1"]
               this.$router.replace({path:'/exhibition',query:{path:`展览场地`}})
           }else if(val == 2){
               
                if(localStorage.getItem('locale') == 'en-US'){
                    this.navtitle = 'Conference Center'
                }else{
                    this.navtitle = '会议中心'
                }
               this.activeNames = ["2"]
               this.$router.replace({path:'/exhibition',query:{path:`会议中心`}})
           }else if(val == 3){
               if(localStorage.getItem('locale') == 'en-US'){
                    this.navtitle = 'Exquisite group pictures'
                }else{
                    this.navtitle = '精美组图'
                }
               this.activeNames = ["3"]
               this.$router.replace({path:'/exhibition',query:{path:`精美组图`}})
           }else if(val == 4){
               if(localStorage.getItem('locale') == 'en-US'){
                    this.navtitle = 'vrView'
                }else{
                    this.navtitle = 'VR导览'
                }
               this.activeNames = ["4"]
               window.location.href = "http://www.ybicec.com/vr/"
           }
        }
    }

}
</script>

<style scoped>
    .exhibition{
        width: 100%;
        height: 100%;
    }
    .content{
        width: 100%;
    }
    .navs{
        width: 100%;
        height: auto;
        border-bottom: 0.01rem solid #999;
        margin-bottom: 0.1rem;
    }
    .navs >>> .van-cell{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 0.16rem;
        height: 0.54rem;
    }
    .navs >>> .van-cell__title{
        flex: none;
    }
    .titleitem{
        width: 100%;
        height: 0.62rem;
        line-height: 0.62rem;
        text-align: center;
        font-size: 0.16rem;
    }
   
</style>