<template>
  <div>
         <div class="contents">
            <div class="main" v-for="(v,i) in arr" :key="i">
                <p>{{$t(v.title)}}</p>
                <span>{{$t(v.text)}}</span>
                <img :src="v.url" alt="">
                  <img :src="v.url2" alt="" v-if="v.url2">
                  <img :src="v.url3" alt="" v-if="v.url3">
                  <img :src="v.url4" alt="" v-if="v.url4">
            </div>
         </div>
         <div class="tabb">

                   <table border="1" cellPadding="0" cellSpacing="0" bordercolor="#EBEBEB" style="text-align: center;">
                    <tbody>
                       <tr>
                            <th style="background: #0F6FB4; color: #fff;">
                                {{$t('language.facility')}}
                                <!-- 设施 -->
                            </th>
                            <th style="background: #0F6FB4; color: #fff;" v-for="(item,index) in tablelsit" :key="index">
                            {{item.facilities}}
                               
                            </th>
                        </tr>
                        <tr class="trs">
                            <td>
                                {{$t('language.A1A2pavilion')}}
                                 <!-- A1、A2馆 -->
                            </td>
                            <td  v-for="(item,index) in tablelsit" :key="index">
                            {{item.aPavilion}}
                                 
                            </td>
                        </tr>
                        <tr class="trs">
                            <td>
                                {{$t('language.A3A4')}}
                                 <!-- A3、A4馆 -->
                            </td>
                            <td  v-for="(item,index) in tablelsit" :key="index">
                            {{item.bPavilion}}
                                
                            </td>
                        </tr>
                        <tr class="trs">
                            <td>
                                 {{$t('language.B1B2B3')}}
                                 <!-- B1、B2、B3馆  -->
                            </td>
                            <td  v-for="(item,index) in tablelsit" :key="index">
                            {{item.cPavilion}}
                                 
                            </td>
                        </tr>
                        <tr class="trs">
                            <td>
                                 {{$t('language.functionhall')}}
                               
                            </td>
                            <td  v-for="(item,index) in tablelsit" :key="index">
                            {{item.functionHall}}
                                 
                            </td>
                        </tr>
                        <tr class="trs">
                            <td>
                                {{$t('language.loginhall')}}
                                <!-- 主、次登录大厅 -->
                            </td>
                            <td  v-for="(item,index) in tablelsit" :key="index">
                            {{item.loginHall}}
                                 
                            </td>
                        </tr>

                    </tbody>
                </table>
                </div>
                 <div class="tabb" style="margin_bottom:0.2rem">

                    <table border="1" cellPadding="0" cellSpacing="0" bordercolor="#EBEBEB" style="text-align: center; width:100%">
                    <tr>
                        <th style="background: #0F6FB4; color: #fff;">
                            {{$t('language.ExhibitionHall')}}
                                 <!-- 展厅 -->
                            </th>
                            <th style="background: #0F6FB4; color:#fff;">
                                {{$t('language.Area')}}
                                 <!-- 面积 -->
                            </th>
                            <th style="background: #0F6FB4; color:#fff;">
                                {{$t('language.StoryHeight')}}
                                 <!-- 层高 -->
                            </th>
                            <th style="background: #0F6FB4; color:#fff;">
                                {{$t('language.Freightentrance')}}
                                 <!-- 货运入口 -->
                            </th>
                            <th style="background: #0F6FB4; color:#fff;">
                                {{$t('language.LoadBearing')}}
                                 <!-- 承重 -->
                            </th>
                            <th style="background: #0F6FB4; color:#fff;">
                                {{$t('language.LiftingPointNumber')}}
                                 <!-- 吊点数量/承重 -->
                            </th>
                    </tr>

    
                    <tr class="trs" v-for="(item,index) in tableTwo" :key="index">
                        <td>{{item.exhibitionHall}}</td>
                        <td>{{item.area}}m²</td>
                        <td>{{item.floorHeight}}</td>
                        <td>{{item.freightEntrance}}</td>
                        <td>{{item.bearing}}t/㎡</td>
                        <td>{{item.quantity}}</td>
                    </tr>
                    <!-- <tr class="trs">
                        <td>B2</td>
                        <td>8000m²</td>
                        <td>36.5米</td>
                        <td>5t/㎡</td>
                        <td>54个/500KG</td>
                    </tr>
                    <tr class="trs">
                        <td>B3</td>
                        <td>8000m²</td>
                        <td>33.1m</td>
                        <td>5t/㎡</td>
                        <td>54个/500KG</td>
                    </tr>
                    <tr class="trs">
                        <td>A1</td>
                        <td>7500m²</td>
                        <td>12.9m</td>
                        <td>2t/㎡</td>
                        <td>—</td>
                    </tr>
                    <tr class="trs">
                        <td>A2</td>
                        <td>7500m²</td>
                        <td>12.9m</td>
                        <td>2t/㎡</td>
                        <td>—</td>
                    </tr>
                    <tr class="trs">
                        <td>A3</td>
                        <td>6500m²</td>
                        <td>26.1m</td>
                        <td>2t/㎡</td>
                        <td>48个/500KG</td>
                    </tr>
                    <tr class="trs">
                        <td>A4</td>
                        <td>6500m²</td>
                        <td>26.1m</td>
                        <td>2t/㎡</td>
                        <td>48个/500KG</td>
                    </tr>
                    <tr class="trs">
                        <td>多功能厅</td>
                        <td>4000m²</td>
                        <td>7m</td>
                        <td>2t/㎡</td>
                        <td>无</td>
                    </tr> -->
                </table>
                </div>
      </div>
</template>

<script>
export default {
    data() {
        return {
            activeNames: ['0'], 
            navtitle:"展览场地" ,
             arr:[
               {
                    "title":"language.Venueintroduction",
                    // "title":"场地简介",
                    "text":"language.Exhibitiongalleriesforindoor",
                    // "text":"展览区分为室内展区和室外展区，室内共设有8个展厅，室内展览面积6.6万平方米，总展位3299个，由双首层五加三展厅的标准单元构成，利用场地自然高差巧妙地使8个展厅都能实现地面平进，每个展厅均拥有独立的物流及人流通道；室外展厅面积约3.3万平方米，可为各类会展活动提供展览空间。",
                        url:require('../../assets/image/20210304烟台八角湾会展中心方案文本 (3)_页面_058.png')
                },
                {
                    "title":"language.Logontothehall",
                    // "title":"登录大厅",
                    "text":"language.Thedesignstyleoftheentry",
                    // "text":"登录厅设计风格与外观建筑和谐统一，高挑的室内空间和简约的线条勾勒，让人仿佛置身云海。“繁华虽少减，高雅亦足奇”，大面积超白玻璃幕墙充分的将自然光线引入进来，营造出素雅大气之感。",
                          url:require('../../assets/image/登录厅1.png'),
                        url2:require('../../assets/image/次登陆厅1.png'),
                    
                },
                {
                    "title":"language.Themulti",
                    // "title":"多功能厅",
                    "text":"language.Thelargepillarfunctional",
                    // "text":"4000平米超大无柱多功能厅，简约不失奢华，大气又显精致。适合举办大型会议和宴会、企业年会、各种定制活动。",
                            url:require('../../assets/image/空场.png'),
                        url2:require('../../assets/image/排桌.png'),
                   
                }
            ],
            tablelsit:[]
        }
    },
    created() {
        this.exhibitioncenter()
        this.exhibitionHallcenter()
    },
    methods:{
        //   展览中心技术数据
        exhibitioncenter(){
            let data = {

            }
            this.$api.exhibitioncenter(data,res=>{
                if(res.data.code == 200){
                    let tablelsit = res.data.data.map((item) => {
                       
                       return item;
                    })
                    this.tablelsit = tablelsit
                }
            })
        },
     //   展管技术数据
        exhibitionHallcenter(){
            let data = {

            }
            this.$api.exhibitionHallcenter(data,res=>{
                if(res.data.code == 200){
                    this.tableTwo = res.data.data   
                }
            })
        },
    }
}
</script>

<style scoped>
   .main{
        margin: 0.1rem;
    }
    .main p{
        font-size:0.18rem;
        font-weight: bold;
        margin-bottom: 0.1rem;
    }
    .main img{
        width: 100%;
    }
       .trs:nth-of-type(2n){
        background: #fff;
    }
    .trs:nth-of-type(2n+1){
        background: #F5F5F5;
    }
    .tabb{
        text-align: center;
        margin-bottom: 0.2rem;
    }
    .phone img{
        width: 0.16rem;
        height: 0.16rem;
        vertical-align: middle;
        margin-right: 0.1rem;
    }
    .phone{
        width: 100%;
        display: flex;
        align-items: center;
    }
    .content{
        width: 100%;
    }
</style>